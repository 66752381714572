var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isMobile)?[_c('b-table',{attrs:{"borderless":"","fields":_vm.existingTokensFields,"items":_vm.tokens,"tbody-tr-class":"hover"},scopedSlots:_vm._u([{key:"cell(symbol)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"td-link"},[_c('IconCrypto',{staticClass:"mr-1",attrs:{"coinname":item.symbol.toLowerCase(),"color":"color","format":"svg","width":"18"}}),_c('span',[_vm._v(_vm._s(item.symbol.toUpperCase()))])],1)]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"cell(liquidity)",fn:function(ref){
var item = ref.item;
return [(item.amount_symbol === '$')?[_vm._v("$")]:_vm._e(),_vm._v(" "+_vm._s(item.liquidity)+" "),(item.amount_symbol !== '$')?[_vm._v(_vm._s(item.amount_symbol))]:_vm._e()]}},{key:"cell(volume)",fn:function(ref){
var item = ref.item;
return [(item.amount_symbol === '$')?[_vm._v("$")]:_vm._e(),_vm._v(" "+_vm._s(item.volume)+" "),(item.amount_symbol !== '$')?[_vm._v(_vm._s(item.amount_symbol))]:_vm._e()]}},{key:"cell(transactions)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transactions)+" ")]}}],null,false,4174378812)})]:_vm._e(),(_vm.isMobile)?_vm._l((_vm.tokens),function(item,index){return _c('div',{key:'token'+index,staticClass:"pool"},[_c('div',{staticClass:"pool-header"},[_c('div',[_c('IconCrypto',{staticClass:"mr-1",attrs:{"coinname":item.symbol.toLowerCase(),"color":"color","format":"svg","width":"18"}}),_c('span',[_vm._v(_vm._s(item.symbol.toUpperCase()))])],1),_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"pool-body"},[_c('div',{staticClass:"pool-row"},[_c('div',{staticClass:"caption mr-3"},[_vm._v("Liquidity:")]),(item.amount_symbol === '$')?[_vm._v("$")]:_vm._e(),_c('div',[_vm._v(_vm._s(item.liquidity))]),(item.amount_symbol !== '$')?[_vm._v(_vm._s(item.amount_symbol))]:_vm._e()],2),_c('div',{staticClass:"pool-row"},[_c('div',{staticClass:"caption mr-3"},[_vm._v("Volume:")]),_c('div',[(item.amount_symbol === '$')?[_vm._v("$")]:_vm._e(),_vm._v(" "+_vm._s(item.volume)+" "),(item.amount_symbol !== '$')?[_vm._v(_vm._s(item.amount_symbol))]:_vm._e()],2)]),_c('div',{staticClass:"pool-row"},[_c('div',{staticClass:"caption mr-3"},[_vm._v("Transactions:")]),_c('div',[_vm._v(" "+_vm._s(item.transactions)+" ")])])])])}):_vm._e(),(_vm.scroll)?_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }