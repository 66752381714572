<template>
    <div>
        <template v-if="!isMobile">
            <b-table borderless
                     :fields="existingTokensFields"
                     :items="tokens"
                     tbody-tr-class="hover">
                <template v-slot:cell(symbol)="{item}">
                    <div class="td-link">
                        <IconCrypto :coinname="item.symbol.toLowerCase()" color="color" format="svg" class="mr-1"
                                    width="18"/>
                        <span>{{ item.symbol.toUpperCase() }}</span>
                    </div>
                </template>
                <template v-slot:cell(name)="{item}">
                    {{item.name}}
                </template>
                <template v-slot:cell(liquidity)="{item}">
                    <template v-if="item.amount_symbol === '$'">$</template>
                    {{item.liquidity}}
                    <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                </template>
                <template v-slot:cell(volume)="{item}">
                    <template v-if="item.amount_symbol === '$'">$</template>
                    {{item.volume}}
                    <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                </template>
                <template v-slot:cell(transactions)="{item}">
                    {{item.transactions}}
                </template>

            </b-table>
        </template>

        <template v-if="isMobile">
            <div class="pool" v-for="(item, index) in tokens" :key="'token'+index">
                <div class="pool-header">
                    <div>
                        <IconCrypto :coinname="item.symbol.toLowerCase()" color="color" format="svg" class="mr-1"
                                    width="18"/>
                        <span>{{ item.symbol.toUpperCase() }}</span>
                    </div>
                    <div>
                        {{item.name}}
                    </div>
                </div>
                <div class="pool-body">
                    <div class="pool-row">
                        <div class="caption mr-3">Liquidity:</div>
                        <template v-if="item.amount_symbol === '$'">$</template>
                        <div>{{ item.liquidity }}</div>
                        <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                    <div class="pool-row">
                        <div class="caption mr-3">Volume:</div>
                        <div>
                            <template v-if="item.amount_symbol === '$'">$</template>
                            {{ item.volume }}
                            <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                        </div>
                    </div>
                    <div class="pool-row">
                        <div class="caption mr-3">Transactions:</div>
                        <div>
                            {{ item.transactions }}
                        </div>
                    </div>
                </div>
            </div>

        </template>

        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" v-if="scroll">
            <span slot="no-more"></span>
            <div slot="no-results"></div>
        </infinite-loading>

    </div>
</template>

<script>
    export default {
        name: "TokensList",
        props: {
            tokens: Array,
            scroll: Boolean,
        },
        computed: {
            isMobile() {
                return window.innerWidth <= 768;
            }
        },
        data: function () {
            return {
                existingTokensFields: [
                    {key: 'symbol', thClass: 'caption'},
                    {key: 'name', thClass: 'caption'},
                    {key: 'liquidity', thClass: 'caption'},
                    {key: 'volume', thClass: 'caption'},
                    {key: 'transactions', thClass: 'caption'}
                ],
                page: 1,
                size: 10,
            }
        }

    }
</script>

<style scoped>

</style>
